<script>
import appConfig from "@/app.config";

/**
 * Recover password-2 component
 */
export default {
  page: {
    title: "Recover password-2",
    meta: [{ name: "description", content: appConfig.description }],
  },
};
</script>
<template>
  <div>
    <!-- Begin page -->
    <div
      class="accountbg"
      :style="{
        background: 'url(' + require('@/assets/images/bg.jpg') + ')',
        'background-size': 'cover',
        'background-position': 'center',
      }"
    ></div>

    <div class="wrapper-page account-page-full">
      <div class="card shadow-none">
        <div class="card-block">
          <div class="account-box">
            <div class="card-box shadow-none p-4">
              <div class="p-2">
                <div class="text-center mt-4">
                  <router-link to="/">
                    <img
                      src="@/assets/images/logo-dark.jpg"
                      height="22"
                      alt="logo"
                    />
                  </router-link>
                </div>

                <h4 class="font-size-18 mt-5 text-center">Reset Password</h4>

                <form class="mt-4" action="#">
                  <div class="alert alert-success mt-4" role="alert">
                    Enter your Email and instructions will be sent to you!
                  </div>

                  <div class="mb-3">
                    <label class="form-label" for="useremail">Email</label>
                    <input
                      type="email"
                      class="form-control"
                      id="useremail"
                      placeholder="Enter email"
                    />
                  </div>

                  <div class="form-group row">
                    <div class="col-12 text-end">
                      <button class="btn btn-primary w-md" type="submit">
                        Reset
                      </button>
                    </div>
                  </div>

                  <div class="form-group mt-2 mb-0 row">
                    <div class="col-12 mt-3">
                      <router-link to="/pages/recoverpwd-2">
                        <i class="mdi mdi-lock"></i> Forgot your password?
                      </router-link>
                    </div>
                  </div>
                </form>

                <div class="mt-5 pt-4 text-center">
                  <p>
                    Remember It ?
                    <router-link
                      to="/pages/login-2"
                      class="fw-medium text-primary"
                      >Sign In here</router-link
                    >
                  </p>
                  <p>
                    ©
                    {{ new Date().getFullYear() }}
                    Veltrix. Crafted with
                    <i class="mdi mdi-heart text-danger"></i> by Themesbrand
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
